import React from 'react'

import { Disclosure } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@radix-ui/react-icons'
import tw from 'twin.macro'

import { useDrillPatterns } from 'content-queries/cx/drill-patterns'
import { WebsiteButton as Button } from 'mx/components/section-components'
import { Section } from 'mx/section'

const P = (props: any) => (
  <p tw="font-light text-gray-800 max-w-xs md:max-w-sm" {...props} />
)

const DetailContainerSingle = tw(Disclosure.Panel)`px-4 pt-4 pb-8 lg:(px-16)`
const DetailContainerDual = tw(
  Disclosure.Panel,
)`pb-12 pt-4 px-4 space-y-4 grid lg:(space-y-0 pl-16 grid-cols-2)`

const SectionDetails = ({
  category,
  setCatalogPopupStatus,
  setQualityPopupStatus,
}: {
  category?: any
  setCatalogPopupStatus?: (x: boolean) => void
  setQualityPopupStatus?: (x: boolean) => void
}) => {
  const { data: drillPatterns } = useDrillPatterns()

  return (
    <>
      <Section padding="top">
        <div id="details" tw="">
          <div tw="mb-6 pl-4 md:(pl-6)">
            <h2 tw="text-4xl leading-none font-thin max-w-md">Details</h2>
          </div>
          <div tw="">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button tw="outline-none rounded-none w-full px-4 py-4 border-b border-dashed flex justify-between text-gray-800 items-center transition-colors md:(px-6) focus:(outline-none) hover:(bg-gray-500/10)">
                    <p tw="text-2xl font-thin text-gray-800">
                      Handle positions
                    </p>
                    {open ? (
                      <MinusIcon color="currentColor" tw="w-4 h-4" />
                    ) : (
                      <PlusIcon color="currentColor" tw="w-4 h-4" />
                    )}
                  </Disclosure.Button>
                  <DetailContainerDual>
                    <div tw="max-w-sm">
                      <p tw="font-light text-gray-800 max-w-xs mb-2 md:(max-w-sm)">
                        We offer 6 handle positions. Please note, specific
                        handles may require specific positions.
                      </p>
                      <p tw="font-light text-gray-800 mb-2 max-w-xs md:(max-w-sm)">
                        You can <strong>learn more</strong> about these
                        positions, or you can <strong>browse</strong> our
                        handles.
                      </p>
                    </div>
                    <div tw="mt-4 grid grid-cols-3 gap-6 w-full md:(mt-0)">
                      {drillPatterns?.map(
                        (drill: {
                          title: string
                          image: { file: { url: string } }
                        }) => {
                          return (
                            drill.title !== 'NA' && (
                              <div key={drill.title}>
                                <img tw="w-32" src={drill?.image?.file?.url} />
                                <p tw="text-base font-light text-gray-600 max-w-xs mt-3 md:(max-w-sm)">
                                  Position {drill.title}
                                </p>
                              </div>
                            )
                          )
                        },
                      )}
                    </div>
                  </DetailContainerDual>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button tw="outline-none rounded-none w-full px-4 py-4 border-b border-dashed flex justify-between text-gray-800 items-center transform transition-colors md:(px-6) focus:(outline-none) hover:(bg-gray-500/10)">
                    <p tw="text-2xl font-thin text-gray-800">Sizing</p>
                    {open ? (
                      <MinusIcon color="currentColor" tw="w-4 h-4" />
                    ) : (
                      <PlusIcon color="currentColor" tw="w-4 h-4" />
                    )}
                  </Disclosure.Button>
                  <DetailContainerSingle>
                    <div tw="max-w-sm">
                      <p tw="mb-4 font-light leading-relaxed text-gray-800 md:mb-8 max-w-xs md:(max-w-sm)">
                        Our products come in all of our unit sizes, including:
                        Base units, tall units, wall units, and corner units. To
                        learn more about these, download our full catalog.
                      </p>
                      <Button
                        onClick={() => {
                          setCatalogPopupStatus?.(true)
                        }}
                        rounded
                        color="transparentBlack"
                        loadingCentered={false}
                      >
                        Download catalog
                      </Button>
                    </div>
                  </DetailContainerSingle>
                </>
              )}
            </Disclosure>

            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button tw="outline-none rounded-none w-full px-4 py-4 border-b border-dashed flex justify-between text-gray-800 items-center transform transition-colors md:(px-6) focus:(outline-none) hover:(bg-gray-500/10)">
                    <p tw="text-2xl font-thin text-gray-800">
                      Care & Maintenance
                    </p>
                    {open ? (
                      <MinusIcon color="currentColor" tw="w-4 h-4" />
                    ) : (
                      <PlusIcon color="currentColor" tw="w-4 h-4" />
                    )}
                  </Disclosure.Button>
                  <DetailContainerSingle>
                    <div tw="space-y-4 mb-8">
                      <P>
                        The high quality surfaces of a kitchen are generally
                        very easy to care for. To retain the value and prolong
                        the service life of your new kitchen for many years to
                        come, you should, however, regularly and above all,
                        properly, care for it.
                      </P>

                      <P>
                        With regular care and maintenance, the {category} Range
                        will last at minimum through our 25-year warranty.
                      </P>
                      <P>
                        Learn more about the quality of our cabinetry in our
                        quality guide.
                      </P>
                    </div>
                    <Button
                      onClick={() => {
                        setQualityPopupStatus?.(true)
                      }}
                      loadingCentered={false}
                      rounded
                      color="transparentBlack"
                    >
                      Download quality guide
                    </Button>
                  </DetailContainerSingle>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </Section>
    </>
  )
}

export { SectionDetails }
