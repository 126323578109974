import React from 'react'

import { Link, navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Slider, { LazyLoadTypes } from 'react-slick'
import tw from 'twin.macro'

import { useFrontItems } from 'content-queries/cx/front-items'
import { WebsiteButton as Button } from 'mx/components/section-components'
import { NextArrow, PreviousArrow } from 'mx/cx/intro/shared'
import {
  IntroContent,
  IntroContentDescription,
  IntroContentLead,
  IntroContentTitle,
  IntroSectionSliderContainer,
} from 'mx/cx/intro/styles'

const Fronts = () => {
  const { data: content } = useFrontItems()

  const settingsFrontsSlider = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    focusOnSelect: false,
    lazyLoad: 'ondemand' as LazyLoadTypes,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <IntroContent>
        <IntroContentLead>
          <IntroContentTitle>Cabinetry fronts</IntroContentTitle>
          <IntroContentDescription>
            We offer over 70 different cabinetry fronts across 12 ranges in
            shaker and flat-panel designs.
          </IntroContentDescription>
        </IntroContentLead>
        <Button
          color="transparentBlack"
          rounded
          onClick={() => navigate('/catalog/fronts')}
        >
          View all fronts
        </Button>
      </IntroContent>
      <IntroSectionSliderContainer>
        <Slider
          tw="col-span-2 py-2 text-center md:(col-span-4 py-4)"
          {...settingsFrontsSlider}
        >
          {content.slice(0, 8).map(
            (
              option: {
                description: string
                imageMain?: {
                  localFile?: {
                    childImageSharp?: {
                      gatsbyImageData?: IGatsbyImageData
                    }
                  }
                }
                imageMainClose?: {
                  localFile?: {
                    childImageSharp?: {
                      gatsbyImageData?: IGatsbyImageData
                    }
                  }
                }
                optionKey: string
                priceRange: string
              },
              index: number,
            ) => {
              return (
                <Link
                  tw="w-full p-4 md:(hover:shadow-md rounded-md p-0) h-[max-content]"
                  to={`/catalog/fronts/${option.optionKey}`}
                  key={index}
                >
                  <div tw="relative p-2 sm:(pt-10 pb-6 px-6) lg:(p-10)">
                    <div>
                      <div tw="relative">
                        <div
                          css={[
                            option.imageMainClose &&
                              tw`md:(z-10 opacity-100 hover:opacity-0 z-0 transition-all)`,
                          ]}
                        >
                          {option.imageMain ? (
                            <GatsbyImage
                              alt={`Front ${option.optionKey}`}
                              tw=""
                              image={
                                option.imageMain?.localFile?.childImageSharp
                                  ?.gatsbyImageData as IGatsbyImageData
                              }
                            />
                          ) : null}
                        </div>
                        {option.imageMainClose ? (
                          <div tw="absolute -translate-x-2/4 -translate-y-2/4 top-2/4 left-2/4 w-full max-h-full z-10 opacity-0 md:(hover:opacity-100 transform transition-all)">
                            <GatsbyImage
                              tw="static"
                              alt={`Front ${option.optionKey} secondary`}
                              image={
                                option.imageMainClose?.localFile
                                  ?.childImageSharp
                                  ?.gatsbyImageData as IGatsbyImageData
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                      <div tw="space-y-0.5 text-left">
                        <div tw="flex items-center space-x-3 pt-4">
                          <p tw="text-black font-sans font-normal">
                            {option.optionKey}
                          </p>
                        </div>
                        <p tw="text-base text-gray-600 font-light">
                          {option.description}
                        </p>
                        <p tw="text-base text-gray-600 font-light">
                          Price Range:
                          <span tw="text-gray-700"> {option.priceRange}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            },
          )}
        </Slider>
      </IntroSectionSliderContainer>
    </React.Fragment>
  )
}

export { Fronts }
