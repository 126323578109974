import tw from 'twin.macro'

// intro sections
const IntroContent = tw.div`flex flex-col items-start px-4 border-b border-dashed pb-10 md:(flex-row items-end justify-between pr-6 pl-4) xl:(pl-0)`
const IntroContentLead = tw.div`space-y-3 mb-8 md:(pl-4 mb-0 space-y-4)`
const IntroContentTitle = tw.h2`text-4xl leading-tighter font-thin max-w-md`
const IntroContentDescription = tw.p`text-gray-800 text-lg font-light leading-relaxed max-w-xs md:(max-w-sm)`
const IntroSectionSliderContainer = tw.div`grid grid-cols-2 md:(grid-cols-3) lg:(grid-cols-4)`

export {
  IntroContent,
  IntroContentLead,
  IntroContentTitle,
  IntroContentDescription,
  IntroSectionSliderContainer,
}
