import React from 'react'

import { motion } from 'framer-motion'
import tw from 'twin.macro'

import { useIntroHero } from 'content-queries/cx/intro-hero'
import fireEvent from 'context/tracking/events'
import { WebsiteButton as Button } from 'mx/components/section-components'
import { Section } from 'mx/section'

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0, y: 75 },
}

const AltHero = ({ action }: { action: (x: boolean) => void }) => {
  const { data } = useIntroHero()
  const {
    moduleMisc: {
      title,
      description,
      action: { text: actionText },
    },
  } = data[0].node

  return (
    <Section padding="normal">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        tw="grid gap-y-4 auto-rows-min md:(grid-cols-9 items-end) lg:(gap-y-8 grid-cols-16)"
      >
        <div tw="pl-4 md:(col-start-1 col-end-7) lg:(col-start-1 col-end-12 shrink-0 pl-10)">
          <h1 tw="font-thin leading-tight text-4xl md:(leading-tighter)">
            {title}
          </h1>
        </div>

        <div tw="pl-4 space-y-4 md:(space-y-0 row-start-1 col-start-7 col-end-10) lg:(col-start-12 col-end-17)">
          <p tw="text-gray-800 text-xl font-light max-w-xs md:(mb-8 text-base leading-relaxed) lg:(leading-relaxed text-lg max-w-xs pr-4) xl:(leading-relaxed)">
            {description}
          </p>
          <Button
            onClick={() => {
              fireEvent({
                type: 'guide_downloaded_catalog',
                guideName: 'Catalog',
              })
              action(true)
            }}
            color="transparentBlack"
            rounded
            css={[
              tw`text-white bg-gray-900 hover:bg-gray-600 dark:hover:bg-gray-600 focus:ring-brand-200`,
            ]}
          >
            {actionText}
          </Button>
        </div>
      </motion.div>
    </Section>
  )
}

export { AltHero }
