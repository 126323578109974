import { useStaticQuery, graphql } from 'gatsby'

export const useIntroHardware = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxModules(
        filter: { contentful_id: { eq: "mPZjvnh6foLm2eT8zmOYZ" } }
      ) {
        data: edges {
          node {
            moduleTitle
            moduleMisc {
              description
            }
            contentSlots {
              slotTitle
              description
              actionText
              actionUrl
              photos {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 675, formats: [WEBP])
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
