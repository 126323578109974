import React, { SyntheticEvent, useContext, useEffect, useState } from 'react'

import * as Dialog from '@radix-ui/react-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import { getSrc } from 'gatsby-plugin-image'
import { styled } from 'twin.macro'

import { useDownloadCatalog } from 'content-queries/mx/download-catalog'
import { AutomaticPopupContext } from 'context/automatic-popup/provider'
import useLeadMutate from 'context/lead/use-mutate'
import fireEvent from 'context/tracking/events'
import {
  getShareASaleClickId,
  TrackingContext,
} from 'context/tracking/provider'
import { WebsiteButton as Button } from 'mx/components/section-components'
import { FormContactSource } from 'types/crm'
import { emailIsValid } from 'views/utils'

interface PopupProps {
  contactSource?: FormContactSource
  onCloseModal: () => void
  onSubmit: () => void
  openModal: boolean
}

const StyledOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
`
const StyledContent = styled(Dialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 200px;
  max-width: fit-content;
  max-height: 85vh;
  flex-shrink: 0;

  background-color: white;
  border-radius: 6px;
  &:focus {
    outline: none;
  }
  @media (min-width: 768px) {
    margin-top: -5vh;
    max-height: 600px;
    max-width: 800px;
  }
`
export const CatalogPopup = ({
  contactSource,
  onCloseModal,
  onSubmit,
  openModal,
}: PopupProps) => {
  const [email, setEmail] = useState<string>('')
  const { state: utmState } = useContext(TrackingContext)
  const { dispatch } = useContext(AutomaticPopupContext)
  useEffect(() => {
    if (openModal) {
      fireEvent({
        type: 'guide_download_started',
        guideName: 'Catalog',
      })
      dispatch({
        type: 'popup_enabled',
        value: false,
      })
    }
  }, [openModal])

  const {
    upsertLead,
    loading: loadingUpsertLead,
    error,
  } = useLeadMutate({
    onCompleted: ({ upsertOneLead }) => {
      if (upsertOneLead?.hubspotId) {
        fireEvent({
          type: 'identify',
          userId: upsertOneLead.hubspotId,
          email,
        })
        fireEvent({
          type: 'lead_upserted',
          hubspotId: upsertOneLead.hubspotId,
        })
        fireEvent({
          type: 'guide_downloaded',
          guideName: 'Catalog',
          email,
        })
      }
      onSubmit()
    },
    onError: (error) => {
      console.error('Email popup error: ', error)
    },
  })

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!loadingUpsertLead) {
      const lead = {
        data: {
          form_contact_source: contactSource,
          shareASaleTrackingCode: getShareASaleClickId(),
        },
        dataTracking: {
          utmCampaign: utmState.utmccn,
          utmContent: utmState.utmcct,
          utmMedium: utmState.utmcmd,
          utmSource: utmState.utmcsr,
          utmTerm: utmState.utmctr,
          gclid: utmState.gclid,
        },
        email,
      }

      await upsertLead({
        variables: {
          create: lead,
          update: {},
          where: {
            email,
          },
        },
      })
    }
  }

  const { data } = useDownloadCatalog()

  const [
    {
      node: {
        sectionModules: [first],
      },
    },
  ] = data

  const {
    moduleTitle,
    moduleMisc: {
      description,
      action: { text: actionText },
    },
    images,
  } = first

  const onChangeProspectEmail = (_: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(_.target.value)
  }

  return (
    <Dialog.Root tw="z-30" open={openModal} onOpenChange={onCloseModal}>
      <StyledOverlay />
      <StyledContent tw="w-11/12 shadow-2xl overflow-y-auto z-50 md:(w-5/6)">
        <div tw="flex flex-col md:(flex-row)">
          <div tw="w-full px-4 pb-8 pt-4 flex flex-col justify-around order-2 sm:(p-6) md:(order-1 w-1/2)">
            <div tw="space-y-4 mt-4 mb-6 md:(mt-0 mb-0)">
              <p tw="text-3xl font-thin sm:text-4xl leading-tighter">
                {moduleTitle}
              </p>
              <p tw="text-lg font-light text-gray-500 max-w-xs sm:(text-gray-800)">
                {description}
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div tw="space-y-4">
                <div tw="mt-1 space-y-2">
                  <div tw="flex">
                    <label
                      htmlFor="email"
                      tw="block text-base font-medium text-gray-500"
                    >
                      Please provide your email
                    </label>
                    <span id="email_description" tw="text-base text-gray-500">
                      *
                    </span>
                  </div>
                  <input
                    id="email"
                    name="email"
                    onChange={(e) => onChangeProspectEmail(e)}
                    required
                    value={email}
                    type="email"
                    autoComplete="email"
                    tw="bg-white block px-3 py-2 w-full shadow-sm border focus:ring-gray-100 focus:border-gray-700 border-gray-300 rounded-sm sm:(text-base)"
                  />
                  {error && (
                    <p tw="text-base text-gray-500">
                      Please enter a valid email address
                    </p>
                  )}
                </div>
                <div tw="items-start">
                  <Button
                    loading={loadingUpsertLead}
                    color="transparentBlack"
                    htmlType="submit"
                    rounded
                    disabled={!emailIsValid(email)}
                  >
                    <span>{actionText}</span>
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div tw="h-56 bg-white relative w-full order-1 overflow-hidden sm:(h-80) md:(h-auto w-1/2 order-2 )">
            <img
              tw="object-top md:(relative)"
              alt="The FORM Catalog"
              src={getSrc(
                images?.[0]?.localFile.childImageSharp.gatsbyImageData,
              )}
            />
          </div>
        </div>
        <Dialog.Close tw="absolute top-4 right-4">
          <Cross1Icon />
        </Dialog.Close>
      </StyledContent>
    </Dialog.Root>
  )
}
