import { useStaticQuery, graphql } from 'gatsby'

export const useIntroHero = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxModules(
        filter: { contentful_id: { eq: "4ysXf5nd8dbqLSZXNLlrkH" } }
      ) {
        data: edges {
          node {
            moduleTitle
            moduleMisc {
              pre
              title
              description
              action {
                text
              }
              card {
                color
                finish
                item
                model
                range
              }
            }
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, formats: [JPG])
                }
              }
            }
            contentSlots {
              slotTitle
              meta {
                range
                model
              }
              photos {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 550, formats: [JPG])
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
