import React from 'react'

import 'twin.macro'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useIntroHardware } from 'content-queries/cx/intro-hardware'
import { WebsiteButton as Button } from 'mx/components/section-components'

import {
  IntroContent,
  IntroContentDescription,
  IntroContentLead,
  IntroContentTitle,
} from './styles'
import { Section } from '../../section'

const HardwareSection = () => {
  const { data } = useIntroHardware()
  const [
    {
      node: {
        moduleTitle: title,
        contentSlots: [hinges, drawers, flaps],
      },
    },
  ] = data
  return (
    <Section id="hardware" padding="top">
      <IntroContent>
        <IntroContentLead>
          <IntroContentTitle>{title}</IntroContentTitle>
          <IntroContentDescription>
            All cabinetry comes with award-winning, top-quality hardware.
          </IntroContentDescription>
        </IntroContentLead>
        <Button
          rounded
          color="transparentBlack"
          onClick={() => navigate('/catalog/hardware')}
        >
          Learn more
        </Button>
      </IntroContent>
      <div
        tw="mx-4 pb-4 flex items-stretch space-x-6 md:(space-x-0 grid grid-cols-12 gap-x-6 pl-0)"
        className="scroll-snap-x"
      >
        <div
          tw="flex shrink-0 h-auto w-10/12 col-span-4 md:(w-auto)"
          className="scroll-snap-member"
        >
          <div tw="bg-white border hover:shadow-md rounded-md max-w-sm mx-auto p-4 space-y-4 md:(space-y-6 p-6)">
            <div tw="h-32 bg-gray-500/5 md:(h-48)">
              <GatsbyImage
                alt="Hinge system hardware"
                tw="h-32 md:(h-48)"
                objectFit="cover"
                objectPosition="bottom"
                image={
                  hinges.photos?.[0]?.localFile.childImageSharp.gatsbyImageData
                }
              />
            </div>
            <div tw="space-y-6">
              <p tw="text-gray-800 text-2xl font-thin mb-6 md:(text-3xl)">
                {hinges?.slotTitle}
              </p>
              <p tw="text-gray-500 font-light leading-relaxed">
                {hinges.description}
              </p>
            </div>
          </div>
        </div>
        <div
          tw="flex shrink-0 h-auto w-10/12 col-span-4 md:(w-auto)"
          className="scroll-snap-member"
        >
          <div tw="bg-white h-full border hover:shadow-md rounded-md max-w-sm mx-auto p-4 space-y-4 md:(space-y-6 p-6)">
            <div tw="h-32 bg-gray-500/5 md:(h-48)">
              <GatsbyImage
                alt="Drawer system hardware"
                tw="h-32 md:(h-48)"
                objectFit="cover"
                objectPosition="bottom"
                image={
                  drawers.photos?.[0]?.localFile.childImageSharp.gatsbyImageData
                }
              />
            </div>
            <div tw="space-y-6">
              <p tw="text-gray-800 text-2xl font-thin mb-6 md:(text-3xl)">
                {drawers?.slotTitle}
              </p>
              <p tw="text-gray-500 font-light leading-relaxed">
                {drawers?.description}
              </p>
            </div>
          </div>
        </div>
        <div
          tw="flex shrink-0 h-auto w-10/12 col-span-4 md:w-auto"
          className="scroll-snap-member"
        >
          <div tw="bg-white border hover:shadow-md rounded-md max-w-sm mx-auto p-4 space-y-4 md:(space-y-6 p-6)">
            <div tw="h-32 bg-gray-500/5 md:(h-48)">
              <GatsbyImage
                alt="Flap system hardware"
                tw="h-32 md:(h-48)"
                objectFit="cover"
                objectPosition="center"
                image={
                  flaps.photos?.[0]?.localFile.childImageSharp.gatsbyImageData
                }
              />
            </div>
            <div tw="space-y-6">
              <p tw="text-gray-800 text-2xl font-thin mb-6 md:(text-3xl)">
                {flaps?.slotTitle}
              </p>
              <p tw="text-gray-500 font-light leading-relaxed">
                {flaps?.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export { HardwareSection }
