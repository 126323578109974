import React from 'react'

import 'twin.macro'
import { motion } from 'framer-motion'
import { Link } from 'react-scroll'

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0, y: -75 },
}

const Navigation = ({ stickyTop }: { stickyTop?: number }) => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      style={{ top: stickyTop }}
      tw="sticky z-20 w-full bg-gray-50 text-gray-800 font-sans font-light text-base"
    >
      <div
        className="catalog-navbar scroll-snap-x"
        tw="max-w-screen-xl flex flex-nowrap items-center justify-between mx-auto md:(overflow-hidden)"
      >
        <Link
          tw="w-1/3 p-4 text-center cursor-pointer md:(w-1/4)"
          className="scroll-snap-member"
          activeClass="active"
          offset={-137}
          spy
          to="fronts"
        >
          Fronts
        </Link>
        <Link
          tw="w-1/3 p-4 text-center cursor-pointer md:(w-1/4)"
          className="scroll-snap-member"
          activeClass="active"
          offset={-137}
          spy
          to="handles"
        >
          Handles
        </Link>
        <Link
          tw="w-1/3 p-4 text-center cursor-pointer md:(w-1/4)"
          className="scroll-snap-member"
          activeClass="active"
          offset={-137}
          spy
          to="features"
        >
          Features
        </Link>
        <Link
          tw="w-1/3 p-4 text-center cursor-pointer md:(w-1/4)"
          className="scroll-snap-member"
          activeClass="active"
          offset={-137}
          spy
          to="hardware"
        >
          Hardware
        </Link>
      </div>
    </motion.div>
  )
}

export { Navigation }
