import React from 'react'

import 'twin.macro'
import { Link, navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Slider, { LazyLoadTypes } from 'react-slick'

import { useHandleItems } from 'content-queries/cx/handle-items'
import { WebsiteButton as Button } from 'mx/components/section-components'
import { NextArrow, PreviousArrow } from 'mx/cx/intro/shared'
import {
  IntroContent,
  IntroContentDescription,
  IntroContentLead,
  IntroContentTitle,
  IntroSectionSliderContainer,
} from 'mx/cx/intro/styles'

const Handles = () => {
  const { data: content } = useHandleItems()

  const settingsHandlesSlider = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    focusOnSelect: false,
    lazyLoad: 'ondemand' as LazyLoadTypes,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <IntroContent>
        <IntroContentLead>
          <IntroContentTitle>Handles</IntroContentTitle>
          <IntroContentDescription>
            We offer over 100 different handles across a number of types
            including standard, bar, integrated, and handleless.
          </IntroContentDescription>
        </IntroContentLead>
        <Button
          rounded
          color="transparentBlack"
          onClick={() => navigate('/catalog/handles')}
        >
          View all handles
        </Button>
      </IntroContent>
      <IntroSectionSliderContainer>
        <Slider
          tw="col-span-2 py-2 text-center md:(col-span-4 py-4)"
          {...settingsHandlesSlider}
        >
          {content.slice(0, 8).map(
            (
              handle: {
                description: string
                imageMain?: {
                  localFile?: {
                    childImageSharp?: {
                      gatsbyImageData?: IGatsbyImageData
                    }
                  }
                }
                optionKey: string
                priceRange: string
              },
              index: number,
            ) => {
              return (
                <Link
                  tw="w-full p-4 md:(hover:shadow-md rounded-md p-0) h-[max-content]"
                  to={`/catalog/handles/${handle.optionKey}`}
                  key={index}
                >
                  <div tw="relative p-2 sm:(pt-10 pb-6 px-6) lg:(p-10)">
                    <div>
                      <div tw="relative">
                        <div>
                          {handle.imageMain ? (
                            <GatsbyImage
                              alt={`Handle ${handle.optionKey}`}
                              tw=""
                              image={
                                handle.imageMain?.localFile?.childImageSharp
                                  ?.gatsbyImageData as IGatsbyImageData
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                      <div tw="space-y-0.5 text-left">
                        <div tw="flex items-center space-x-3 pt-4">
                          <p tw="text-black font-sans font-normal">
                            {handle.optionKey}
                          </p>
                        </div>
                        <p tw="text-base text-gray-600 font-light">
                          {handle.description}
                        </p>
                        <p tw="text-base text-gray-600 font-light">
                          Price Range:
                          <span tw="text-gray-700"> {handle.priceRange}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            },
          )}
        </Slider>
      </IntroSectionSliderContainer>
    </React.Fragment>
  )
}

export { Handles }
