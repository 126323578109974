import React from 'react'

import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Slider, { LazyLoadTypes } from 'react-slick'
import tw from 'twin.macro'

import { useFeatureItems } from 'content-queries/cx/feature-items'
import { WebsiteButton as Button } from 'mx/components/section-components'
import { NextArrow, PreviousArrow } from 'mx/cx/intro/shared'
import {
  IntroContent,
  IntroContentDescription,
  IntroContentLead,
  IntroContentTitle,
  IntroSectionSliderContainer,
} from 'mx/cx/intro/styles'

const Features = () => {
  const { data: content } = useFeatureItems()

  const settingsFeaturesSlider = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    focusOnSelect: false,
    lazyLoad: 'ondemand' as LazyLoadTypes,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }
  return (
    <React.Fragment>
      <IntroContent>
        <IntroContentLead>
          <IntroContentTitle>Features</IntroContentTitle>
          <IntroContentDescription>
            Our wide feature set gives you thousands of options to create a
            kitchen that works for you.
          </IntroContentDescription>
        </IntroContentLead>
        <Button
          rounded
          color="transparentBlack"
          onClick={() => navigate('/catalog/features')}
        >
          View all features
        </Button>
      </IntroContent>
      <IntroSectionSliderContainer>
        <Slider
          tw="col-span-2 py-2 text-center md:(col-span-4 py-4)"
          {...settingsFeaturesSlider}
        >
          {content.slice(0, 8).map(
            (
              option: {
                title: string
                usage: string
                category?: {
                  title?: string
                }
                description?: string
                imageMain?: {
                  title?: string
                  localFile?: {
                    childImageSharp?: {
                      gatsbyImageData?: IGatsbyImageData
                    }
                  }
                }
                priceRange?: string
              },
              idx: number,
            ) => {
              return (
                <div
                  tw="w-full h-[max-content] p-4 md:(rounded-md p-0)"
                  key={idx}
                >
                  <div tw="relative p-2 sm:(pt-10 pb-6 px-6) lg:(p-10)">
                    <div>
                      <div tw="relative">
                        {option.imageMain ? (
                          <GatsbyImage
                            alt={option.imageMain.title ?? ''}
                            tw="h-[255px]"
                            imgStyle={tw`object-contain`}
                            image={
                              option.imageMain?.localFile?.childImageSharp
                                ?.gatsbyImageData as IGatsbyImageData
                            }
                          />
                        ) : null}
                      </div>
                      <div tw="space-y-0.5 text-left">
                        <div tw="flex items-center space-x-3 pt-4">
                          <p tw="text-black font-sans font-normal">
                            {option?.description}
                          </p>
                        </div>
                        <p tw="text-base text-gray-600 font-light">
                          Price Range:
                          <span tw="text-gray-700"> {option.priceRange}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            },
          )}
        </Slider>
      </IntroSectionSliderContainer>
    </React.Fragment>
  )
}

export { Features }
