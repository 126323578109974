import { useStaticQuery, graphql } from 'gatsby'

export const useDrillPatterns = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulDrillPattern(sort: { fields: title }) {
        data: nodes {
          id
          contentful_id
          title
          image {
            file {
              url
            }
          }
        }
      }
    }
  `)

  return content
}
